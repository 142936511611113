<template>
  <div>
    <v-row>
      <v-col cols="12">
        Novos Assinantes {{ newSubscriptions }} | Cancelamentos
        {{ cancellations }}| Total Assinantes :{{ totalSubscriptions }}
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col cols="12" md="4" class="d-flex">
        <app-text-field
          @change="select()"
          label="Pesquisar"
          v-model="searchParams.text"
        />
        <v-btn class="ml-2" @click="select()" color="primary">
          <app-icon>search</app-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <app-text-field
          @change="select()"
          v-model="searchParams.company_id"
          label="Company ID"
        />
      </v-col>
      <v-col cols="12" md="4" class="align-center d-flex">
        <v-chip-group
          @change="select()"
          mandatory
          v-model="searchParams.status"
        >
          <v-chip color="primary" filter :value="'active'">Ativos</v-chip>
          <v-chip outlined filter :value="'inactive'">Inativos</v-chip>
        </v-chip-group>
        <v-chip-group
          @change="select()"
          multiple
          v-model="searchParams.subscriber_type"
        >
          <v-chip outlined filter :value="'on_trial'">Em teste</v-chip>
          <v-chip outlined filter :value="'subscribers'">Assinantes</v-chip>
        </v-chip-group>
      </v-col>
      <v-col class="text-right" cols="12" md="2">
        <v-btn
          @click="openNotificationForm()"
          class="ml-auto"
          bottom
          large
          color="primary"
        >
          Notificar Usuários
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-select
          @change="select()"
          label="Método de Pagamento"
          :items="paymentVendors"
          clearable
          v-model="searchParams.payment_vendor"
        />
      </v-col>
      <v-col>
        <app-select
          @change="select()"
          label="Status de assinatura"
          :items="subscriptionsStatus"
          small-chips
          deletable-chips
          multiple
          v-model="searchParams.subscription_status"
        />
      </v-col>
      <v-col cols="3">
        <app-select
          @change="select()"
          :items="plans"
          v-model="searchParams.plan_id"
          :item-text="
            (item) =>
              `${item.name} - ${item.type} - ${item.status} - ${$format.moneyBr(
                item.value
              )} - ${item.vendor}`
          "
          item-value="id"
          label="Plano"
          clearable
        />
      </v-col>
      <v-col cols="3">
        <v-switch
          @change="select()"
          v-model="searchParams.subscription_overdue"
          :true-value="1"
          :false-value="0"
          hide-details
          label="Vencidos"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Registro Inicial"
          v-model="searchParams.created_at_start"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Registro Final"
          v-model="searchParams.created_at_end"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Visita Inicial"
          v-model="searchParams.visited_at_start"
        />
      </v-col>
      <v-col>
        <app-text-field
          @change="select()"
          type="date"
          label="Data Visita Final"
          v-model="searchParams.visited_at_end"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.visit_count_start"
          label="N° Visita Inicial"
        />
      </v-col>

      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.sessions_count_start"
          label="Nº Sessões Inicial"
        />
      </v-col>

      <v-col>
        <app-number-field
          @change="select()"
          :precision="0"
          v-model="searchParams.customer_count_start"
          label="Nº Clientes Inicial"
        />
      </v-col>

      <v-col>
        <v-chip-group
          @change="select()"
          mandatory
          multiple
          v-model="searchParams.company_type"
        >
          <v-chip outlined filter :value="'clinic'">clinica</v-chip>
          <v-chip filter :value="'individual'">individual</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-text-field
          @change="select()"
          v-model="searchParams.signup_metadata"
          label="Signup Metadata"
        />
      </v-col>
      <v-switch
        @change="select()"
        v-model="searchParams.is_beta_tester"
        :true-value="1"
        :false-value="0"
        hide-details
        label="Beta Tester"
      />
      <v-col class="d-flex">
        <v-btn @click="clearSearchParams()" color="secondary">
          limpar filtros
        </v-btn>
        <v-btn @click="exportSearch()" class="ml-auto" color="success">
          Exportar pesquisa
        </v-btn>
        <v-btn @click="exportBase()" class="ml-auto" color="success">
          Exportar Base
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="companies">
          <v-data-table
            dense
            show-select
            v-model="selected_users"
            :headers="headers"
            :items="companies.data"
            hide-default-footer
            loading="true"
            disable-sort
            :items-per-page="-1"
            @click:row="openUserView($event.id)"
          >
            <template v-slot:[`item.name`]="{ item }">
              <CompanyLabel class="py-2" :value="item" />
            </template>
            <template v-slot:[`item.phone_wa`]="{ item }">
              {{ $format.phone(item.owner.user.phone_wa) }}
              <WhatsAppApiBtn
                :phone="item.owner.user.phone_wa"
                color="primary"
                @click.prevent
              />
            </template>
            <!-- <template v-slot:[`item.created_at`]="{ item }">
              {{ $format.dateBr(item.created_at) }}
            </template> -->
            <template v-slot:[`item.data`]="{ item }">
              <app-icon small>history</app-icon>
              {{ $format.dateRelative(item.visited_at) }} ({{
                item.visit_count
              }}) |

              <app-icon small>person</app-icon>
              {{ item.customers_count }} |

              <app-icon small>calendar_today</app-icon>
              {{ item.sessions_count }}
            </template>
            <template v-slot:[`item.subscription`]="{ item }">
              <template v-if="item.last_subscription">
                <span>
                  {{ item.last_subscription.plan.name }} ({{
                    item.last_subscription.status
                  }}, {{ item.last_subscription.vendor }})
                </span>
                <br />
                <small
                  >Fim:
                  {{ $format.dateBr(item.last_subscription.ends_at) }}</small
                >
              </template>
              <span v-if="item.is_free_account == 1">Acesso Gratuito</span>
              <span v-if="!item.last_subscription && item.is_free_account == 0">
                Período de Teste ({{ daysToEnd(item.free_trial_ends_at) }})
                <br />
                <small>
                  Data de cadastro:
                  {{ $format.dateBr(item.created_at) }}
                </small>
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="loginAs(item.owner.user)">
                    <v-list-item-icon>
                      <v-icon>mdi-account-key</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Forçar Login</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openUpdateCompanyStatus(item)">
                    <v-list-item-icon>
                      <v-icon v-text="companyUpdateStatus[item.status].icon" />
                    </v-list-item-icon>
                    <v-list-item-title
                      v-text="companyUpdateStatus[item.status].text"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </app-pagination>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <small class="text--disabled">
          DisplayMode:{{ getPWADisplayMode() }}
          <br />
          PlatformInfo:{{ getPlatformInfo() }}
        </small>
      </v-col>
    </v-row>
    <NotificationForm :selected_users="selected_users" ref="NotificationForm" />
    <CompanyView ref="CompanyView" @store="select()" />
    <UpdateCompanyStatus ref="UpdateCompanyStatus" @store="select()" />
  </div>
</template>

<script>
import WhatsAppApiBtn from "@/components/app/ui/WhatsAppApiBtn.vue";
import CompanyLabel from "@/components/company/sections/CompanyLabel.vue";
import NotificationForm from "@/components/admin/forms/NotificationForm.vue";
import CompanyView from "@/components/admin/sections/CompanyView.vue";
import UpdateCompanyStatus from "@/components/admin/sections/UpdateCompanyStatus.vue";
import { differenceInCalendarDays, parseISO, format } from "date-fns";
export default {
  components: {
    CompanyLabel,
    NotificationForm,
    CompanyView,
    UpdateCompanyStatus,
    WhatsAppApiBtn,
  },
  data() {
    return {
      paymentVendors: [
        { text: "Pagar.Me", value: "pagarme" },
        { text: "PagSeguro", value: "pagseguro" },
        { text: "PIX", value: "pix" },
        { text: "Stripe", value: "stripe" },
        { text: "Efi", value: "efi" },
      ],
      companyUpdateStatus: {
        active: {
          icon: "mdi-lock",
          text: "Desativar",
        },
        inactive: {
          icon: "mdi-lock-open",
          text: "Ativar",
        },
      },

      subscriptionsStatus: [
        { value: "sending", text: "sending" },
        { value: "pending_payment", text: "pending_payment" },
        { value: "waiting_payment", text: "waiting_payment" },
        { value: "processing", text: "processing" },
        { value: "paid", text: "paid" },
        { value: "past_due", text: "past_due" },
        { value: "unpaid", text: "unpaid" },
        { value: "ended", text: "ended" },
        { value: "canceled", text: "canceled" },
      ],

      headers: [
        { text: "Nome", value: "name", align: "start" },
        { text: "WhatsApp", value: "phone_wa", align: "center" },
        // { text: "Registro", value: "created_at", align: "center" },
        { text: "Stats", value: "data", align: "center" },
        { text: "Modo", value: "type", align: "center", width: "1%" },
        {
          text: "Plano",
          value: "subscription",
          align: "center",
        },
        { text: "", value: "action", align: "end", width: "5%" },
      ],

      companies: {},
      newSubscriptions: 0,
      cancellations: 0,
      totalSubscriptions: 0,
      searchParams: {
        text: null,
        subscriber_type: ["on_trial", "subscribers"],
        status: "active",
        subscription_status: null,
        subscription_overdue: 0,
        payment_vendor: null,
        created_at_start: null,
        created_at_end: null,
        visited_at_start: null,
        visited_at_end: null,
        visited_count_start: null,
        visited_count_end: null,
        customers_count_start: null,
        customers_count_end: null,
        sessions_count_start: null,
        sessions_count_end: null,
        plan_id: null,
        company_type: ["individual"],
        page: 1,
      },

      selected_users: [],

      plans: [],
    };
  },

  mounted() {
    if (this.$route.query.recommender_id) {
      this.searchParams.company_id = this.$route.query.recommender_id;
    }
    this.select();
    this.getMobileDevice();
    this.getPlans();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("admin/companies", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.companies = response.companies;
          this.newSubscriptions = response.newSubscriptions;
          this.cancellations = response.cancellations;
          this.totalSubscriptions = response.totalSubscriptions;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    loginAs(user) {
      this.$http.$post("admin/auth", { id: user.id }).then((response) => {
        this.$store.commit("app/loggedAsAnotherUser");

        this.$http.setAuthorizationToken(response.token);

        this.$auth.getUserData().then((response) => {
          this.$router.push("/");
        });
      });
    },

    openUpdateCompanyStatus(company) {
      this.$refs.UpdateCompanyStatus.open({
        company_id: company.id,
        status: company.status,
      });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },
    openNotificationForm() {
      this.$refs.NotificationForm.open();
    },
    openUserView(user_id) {
      this.$refs.CompanyView.open(user_id);
    },

    getPlans() {
      this.$http.index("subscription/plans").then((response) => {
        this.plans = response.plans;

        // this.plans = response.plans.map((plan) => {
        //   return {
        //     text: `${plan.name} - ${plan.type} - ${
        //       plan.status
        //     } - ${this.$format.moneyBr(plan.value)} - ${plan.vendor}`,
        //     value: plan.id,
        //   };
        // });
      });
    },

    daysToEnd(date) {
      let days = differenceInCalendarDays(parseISO(date), new Date());

      if (days < 0) {
        return "Terminou";
      }

      return "restam " + days + " dias";
    },

    getPWADisplayMode() {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      if (document.referrer.startsWith("android-app://")) {
        var mode = "twa";
      } else if (navigator.standalone || isStandalone) {
        var mode = "standalone";
      }
      var mode = "browser";

      let stand_alone = window.matchMedia("(display-mode: standalone)").matches;
      let navigator_sa = navigator.standalone;
      let twa = document.referrer.includes("android-app://");

      return {
        mode: mode,
        stand_alone: stand_alone,
        navigator_sa: navigator_sa,
        twa: twa,
      };
    },
    //retorna informações da plataforma utilizada pelo usuário
    getPlatformInfo() {
      return {
        webtonative: this.$webtonative,
        platform: window.navigator.platform,
        device: this.device,
      };
    },

    getMobileDevice() {
      if (this.$webtonative.platform !== "WEBSITE") {
        this.$webtonative.deviceInfo().then((value) => {
          this.device = value;
        });
      }
    },

    clearSearchParams() {
      this.searchParams = {
        text: null,
        subscriber_type: ["on_trial", "subscribers"],
        status: "active",
        subscription_status: null,
        payment_vendor: null,
        created_at_start: null,
        created_at_end: null,
        visited_at_start: null,
        visited_at_end: null,
        visited_count_start: null,
        visited_count_end: null,
        customers_count_start: null,
        customers_count_end: null,
        sessions_count_start: null,
        sessions_count_end: null,
        plan_id: null,
        signup_metadata: null,
        company_type: ["individual"],
        page: 1,
      };

      if (this.$route.query.recommender_id) {
        this.$router.push({ name: "ManagementIndex" });
      }
      this.select();
    },

    exportSearch() {
      this.$loading.start();
      this.$http
        .download("admin/companies/export", this.searchParams)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Relatório de empresas  - ${format(new Date(), "dd-MM-yyyy")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    exportBase() {
      this.$loading.start();
      this.$http
        .download("admin/companies/export")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Relatório de empresas  - ${format(new Date(), "dd-MM-yyyy")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
