<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <v-card>
      <v-card-title primary-title> Notificar Usuarios </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-radio-group v-model="form.users_to_notified" row>
              <v-radio label="Notificar Todos" value="all"></v-radio>
              <v-radio
                v-if="form.selected_users.length"
                label="Notificar Apenas Selecionados"
                value="selected"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col>
            <v-chip-group mandatory multiple v-model="form.status">
              <v-chip color="primary" filter :value="'active'">
                Usuarios Ativos
              </v-chip>
              <v-chip outlined filter :value="'inactive'"
                >Usuarios Inativos
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <!-- <app-select :items="types" v-model="form.type" label="Tipo" />, -->
            <v-select
              hide-details
              dense
              :color="form.type"
              :items="types"
              v-model="form.type"
              label="Tipo"
            >
              <template v-slot:selection="{ item }">
                {{ item.text }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.text }}
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              hide-details
              dense
              :color="form.type"
              :items="icons"
              v-model="form.icon"
              label="Icone"
            >
              <template v-slot:selection="{ item }"
                ><v-icon :color="form.type" class="mr-2" v-text="item.value" />
                {{ item.text }}
              </template>
              <template v-slot:item="{ item }">
                <v-icon :color="form.type" class="mr-2" v-text="item.value" />
                {{ item.text }}
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-text-field v-model="form.title" label="Titulo" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              v-model="form.message"
              label="Mensagem"
              id="id"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text>Cancelar</v-btn>
        <v-btn @click="sendNotification()" class="mr-2" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    selected_users: {},
  },
  data() {
    return {
      types: [
        { value: "primary", text: "Notificação de Atualização" },
        { value: "success", text: "Notificação de Sucesso" },
        { value: "info", text: "Notificação de Informação" },
        { value: "error", text: "Notificação de Erro" },
      ],
      icons: [
        { value: "mdi-alert-outline", text: "Alerta" },
        { value: "mdi-information-outline", text: "Informação" },
        { value: "mdi-bell-ring-outline", text: "Notificação" },
        { value: "mdi-email-outline", text: "Mensagem" },
        { value: "mdi-update", text: "Atualização" },
        { value: "mdi-calendar", text: "Calendario" },
        { value: "mdi-calendar-clock-outline", text: "Calendario e Relogio" },
        { value: "mdi-party-popper", text: "Popper de Festa" },
      ],
      dialog: false,
      baseForm: {
        type: "primary",
        icon: "mdi-bell-ring-outline",
        title: null,
        message: null,
        selected_users: [],
        users_to_notified: "all",
        status: ["active"],
      },
      form: {},
    };
  },

  created() {
    this.reset();
  },
  methods: {
    open() {
      this.reset();
      this.dialog = true;
      if (this.selected_users.length) {
        this.setSelectedUsers();
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },
    sendNotification() {
      this.$loading.start();
      this.$http
        .store("admin/send-custom-notification", this.form)
        .then((response) => {
          this.dialog = false;
          this.$loading.finish();
        })
        .catch((error) => {
          this.dialog = false;
          this.$loading.finish();
        });
    },

    setSelectedUsers() {
      this.form.selected_users = this.selected_users;
      this.form.users_to_notified = "selected";
    },
  },
};
</script>

<style>
</style>