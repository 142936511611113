<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title> API Evolution </v-card-title>
      <v-card-text>
        <div v-if="!hasInstance" class="d-flex justify-center">
          <v-btn rounded color="primary" @click="store()">
            <v-icon left> mdi-new-box </v-icon>
            Habilitar Evolution
          </v-btn>
        </div>
        <div v-if="hasInstance" class="d-flex justify-space-around">
          <v-btn rounded color="primary" @click="restart">
            <v-icon left> mdi-restart </v-icon>
            Reiniciar
          </v-btn>
          <div>
            <app-cancel-btn
              @click="disconnect"
              text="Desconectar"
              icon="mdi-cellphone-nfc-off"
            />
          </div>
          <div>
            <app-cancel-btn
              @click="destroy"
              text="Deletar Evo"
              icon="mdi-delete"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      company_id: null,
      instance: null,
    };
  },

  computed: {
    hasInstance() {
      return this.instance && this.instance.id !== null;
    },
  },

  methods: {
    open(company) {
      this.setData(company);
      this.dialog = true;
    },

    setData(company) {
      console.log(company);
      this.company_id = company.id;
      this.instance = JSON.parse(JSON.stringify(company.whatsapp_instance));
    },

    async store() {
      await this.$http.store("admin/whatsapp-evo", {
        company_id: this.company_id,
      });
    },

    async restart() {
      await this.$http.store("admin/whatsapp-evo/restart", {
        company_id: this.company_id,
      });
    },

    async disconnect() {
      await this.$http.store("admin/whatsapp-evo/disconnect", {
        company_id: this.company_id,
      });
    },

    async destroy() {
      await this.$http.destroy(`admin/whatsapp-evo/${this.company_id}`);
    },
  },
};
</script>

<style>
</style>