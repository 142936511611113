<template>
  <v-dialog v-model="dialog" max-width="550px">
    <v-card>
      <v-card-title class="d-flex justify-center">
        Você deja realmente {{ titile }} este usuario ?</v-card-title
      >
      <v-card-actions>
        <v-btn @click="dialog = false" class="mr-auto" color="error" text
          >Não</v-btn
        >
        <v-btn @click="handleSave()" color="primary">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        company_id: null,
        status: "active",
      },
    };
  },

  computed: {
    titile() {
      return this.form.status == "active" ? "ativar" : "desativar";
    },
  },

  methods: {
    open(company) {
      this.reset();
      this.form = {
        company_id: company.company_id,
        status: company.status == "active" ? "inactive" : "active",
      };

      this.dialog = true;
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .update("admin/company-update-status", this.form.company_id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    processSaved() {
      this.$emit("store");
      this.dialog = false;
      this.$loading.finish();
    },
  },
};
</script>

<style>
</style>