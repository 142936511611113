var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Notificar Usuarios ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.form.users_to_notified),callback:function ($$v) {_vm.$set(_vm.form, "users_to_notified", $$v)},expression:"form.users_to_notified"}},[_c('v-radio',{attrs:{"label":"Notificar Todos","value":"all"}}),(_vm.form.selected_users.length)?_c('v-radio',{attrs:{"label":"Notificar Apenas Selecionados","value":"selected"}}):_vm._e()],1)],1),_c('v-col',[_c('v-chip-group',{attrs:{"mandatory":"","multiple":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('v-chip',{attrs:{"color":"primary","filter":"","value":'active'}},[_vm._v(" Usuarios Ativos ")]),_c('v-chip',{attrs:{"outlined":"","filter":"","value":'inactive'}},[_vm._v("Usuarios Inativos ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"hide-details":"","dense":"","color":_vm.form.type,"items":_vm.types,"label":"Tipo"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-col',[_c('v-select',{attrs:{"hide-details":"","dense":"","color":_vm.form.type,"items":_vm.icons,"label":"Icone"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.form.type},domProps:{"textContent":_vm._s(item.value)}}),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.form.type},domProps:{"textContent":_vm._s(item.value)}}),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}})],1)],1),_c('v-row',[_c('v-col',[_c('app-text-field',{attrs:{"label":"Titulo"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":"Mensagem","id":"id"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.sendNotification()}}},[_vm._v("Salvar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }