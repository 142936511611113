<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="mb-2" primary-title>
        Configurações da clinica
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <app-date-field
              v-model="form.free_trial_ends_at"
              type="date"
              label="Fim do Período de Teste"
            />
          </v-col>
          <v-col>
            <app-date-field
              v-model="form.no_block_access_before"
              type="date"
              label="Não bloquear o acesso antes de"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-select label="Modo" :items="typeOptions" v-model="form.type" />
          </v-col>

          <v-col>
            <app-number-field
              v-model="form.professionals_limit"
              :precision="0"
              label="Limite de Profissionais"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch label="Beta Teste" v-model="form.is_beta_tester" />
          </v-col>
          <v-col>
            <v-switch
              label="Acesso Gratuito Habilitado"
              v-model="form.is_free_account"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text>Cancelar</v-btn>
        <v-btn @click="handleSave()" class="mr-2" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      baseForm: {
        company_id: null,
        is_free_account: 0,
        is_beta_tester: false,
        free_trial_ends_at: null,
        no_block_access_before: null,
        type: null,
        professionals_limit: null,
      },
      form: {},

      typeOptions: [
        { text: "Individual", value: "individual" },
        { text: "Clínica", value: "clinic" },
      ],

      dialog: false,
    };
  },
  created() {
    this.reset();
  },
  computed: {},
  methods: {
    open(company_id) {
      this.reset();
      this.$loading.start();
      this.$http
        .show("admin/companies", company_id)
        .then((response) => {
          this.$loading.finish();
          this.setForm(response.company);
          this.dialog = true;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setForm(company) {
      this.form = {
        id: company.id,
        is_free_account: company.is_free_account,
        type: company.type,
        professionals_limit: company.professionals_limit,
        free_trial_ends_at: this.$format.dateBr(
          company.free_trial_ends_at,
          "yyyy-MM-dd"
        ),
        no_block_access_before: this.$format.dateBr(
          company.no_block_access_before,
          "yyyy-MM-dd"
        ),
        has_chatbot: company.has_chatbot,
        is_beta_tester: company.is_beta_tester,
      };
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .update("admin/companies", this.form.id, this.form)
        .then((response) => {
          this.$loading.finish();
          this.dialog = false;
          this.$emit("store", this.form.id);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>