<template>
  <v-dialog v-model="dialog" scrollable max-width="900px">
    <v-card>
      <v-card-title class="d-flex align-baseline">
        <span>{{ company.name }} </span> |
        <small class="text--secondary ml-2"> #{{ company.id }}</small>
        <div small class="ml-auto">
          <status-chip :value="company.status" class="mr-2" />
          <v-chip v-if="company.is_beta_tester" color="info" small>
            <v-icon left> mdi-bug </v-icon>Beta Tester
          </v-chip>
        </div>
      </v-card-title>

      <v-card-subtitle class="pb-0">
        Data de Registro: {{ $format.dateBr(company.created_at) }}
      </v-card-subtitle>

      <v-card-text>
        <h3 class="mt-4 mb-2">Informações Básicas</h3>
        <v-row>
          <v-col cols="6">Responsável: {{ company.owner.user.name }}</v-col>
          <v-col cols="6"> Email: {{ company.owner.user.email }} </v-col>
          <v-col> Telefone: {{ company.owner.user.phone_wa }} </v-col>
          <v-col>company_id: {{ company.id }}</v-col>
        </v-row>

        <h3 class="mt-4 mb-2">Endereço Atual</h3>
        <v-row>
          <v-col cols="12" md="4">
            CEP: {{ $format.cep(company.owner.user.main_address.postal_code) }}
          </v-col>
          <v-col cols="12" md="12">
            Endereço: {{ company.owner.user.main_address.address }}
          </v-col>
          <v-col cols="12" md="6">
            Número: {{ company.owner.user.main_address.number }}
          </v-col>
          <v-col cols="12" md="6">
            Bairro: {{ company.owner.user.main_address.district }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" md="6">
            Cidade: {{ company.owner.user.main_address.city }}
          </v-col>
          <v-col cols="4" md="6">
            Estado: {{ company.owner.user.main_address.state }}
          </v-col>
          <v-col cols="12">
            Complemento: {{ company.owner.user.main_address.complement }}
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2 d-flex">
          Configurações da clinica
          <v-btn
            @click="openUpdateCompanyForm()"
            class="ml-auto"
            text
            icon
            color="primary"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </h3>
        <v-row>
          <v-col>
            <span v-if="company.is_free_account == 1"> Acesso Gratuito </span>
            <span v-if="company.last_subscription">
              {{ company.last_subscription.plan.name }}
            </span>
            <span
              v-if="!company.last_subscription && company.is_free_account == 0"
            >
              Período de Teste até
              {{ $format.dateBr(company.free_trial_ends_at) }}
            </span>
          </v-col>
          <v-col v-if="company.no_block_access_before">
            Não bloquear o acesso antes de
            {{ $format.dateBr(company.no_block_access_before) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col> Modo: {{ company.type }} </v-col>
          <v-col>
            Limite de profissionais: {{ company.professionals_limit }}
          </v-col>
        </v-row>
        <h3 class="mt-4 mb-2 d-flex">Configurações do Whatsapp</h3>

        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-btn @click="openWhatsappNotificationForm()" text color="primary">
              <v-icon left>mdi-bell-ring-outline</v-icon>
              Configurar Z-API
            </v-btn>

            <v-btn @click="handleEvo()" text color="primary">
              <v-icon left>mdi-bell-ring-outline</v-icon>
              Configurar Evo
            </v-btn>

            <v-btn @click="resendSchedule()" text color="primary">
              <v-icon left>mdi-refresh</v-icon>
              Reenviar Agenda
            </v-btn>
            <v-btn @click="resendRemindersSession()" text color="primary">
              <v-icon left>mdi-refresh</v-icon>
              Reenviar Lembretes
            </v-btn>
          </v-col>
        </v-row>

        <br />

        <template v-if="company.billing_whats_app_instance_id">
          <h4>Cobrança ({{ billingWhatsAppInstance.id }})</h4>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <span> <b> Nome: </b>{{ billingWhatsAppInstance.name }} </span>
              <span><b> Numero: </b>{{ billingWhatsAppInstance.number }}</span>
              <span>
                <b> ZAPI ID: </b>{{ billingWhatsAppInstance.instance_id }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template v-if="company.session_whats_app_instance_id">
          <br />
          <h4>Sessão ({{ sessionWhatsAppInstance.id }})</h4>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <span> <b>Nome: </b> {{ sessionWhatsAppInstance.name }} </span>
              <span><b>Numero: </b> {{ sessionWhatsAppInstance.number }}</span>
              <span>
                <b>ZAPI ID: </b> {{ sessionWhatsAppInstance.instance_id }}
              </span>
            </v-col>
          </v-row>
        </template>

        <div class="mt-4 mb-2 d-flex flex-column">
          <h3>
            Assinaturas Anteriores

            <v-btn
              @click="openCompanySubscriptionForm()"
              text
              icon
              class="ml-auto"
              color="primary"
            >
              <v-icon>mdi-currency-usd</v-icon>
            </v-btn>
            <v-btn
              @click="openCustomSubscriptionForm()"
              text
              class="ml-auto"
              color="primary"
            >
              Assinar Manualmente
            </v-btn>
          </h3>
          <small v-if="company.recommender_id">
            Indicado por {{ company.recommendation_by.name }}
          </small>
        </div>

        <v-row v-if="company.subscriptions.length">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="company.subscriptions"
              disable-sort
              dense
              @click:row="openCompanySubscriptionForm($event.id)"
              small
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:[`item.start_at`]="{ item }">
                {{ $format.dateBr(item.start_at) }}
              </template>
              <template v-slot:[`item.ends_at`]="{ item }">
                {{ $format.dateBr(item.ends_at) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ subscriptionStatus(item) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-if="item.status != 'canceled'"
                      @click="cancelSubscription(item.id)"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-cancel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Cancelar Assinatura
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <h3 class="mt-4 mb-2 d-flex">Observações:</h3>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="company.observations"
              rows="1"
              auto-grow
              hide-details
            />
          </v-col>

          <v-btn
            @click="storeObservations()"
            small
            class="ml-auto mr-4 mb-4"
            color="success"
            >Salvar</v-btn
          >
        </v-row>

        <h3 class="mt-4 mb-2 d-flex">Metadata</h3>
        Ultimo Acesso: {{ $format.dateBr(company.visited_at) }}
        <small>
          {{ company.research }}
          <br />
          {{ company.signup_metadata }}
        </small>

        <template v-if="company.type == 'clinic'">
          <h3 class="mt-4 mb-2 d-flex">Usuários</h3>
          <v-row>
            <v-col>
              <v-data-table
                :headers="userHeaders"
                :items="company.users"
                disable-sort
                dense
                small
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:[`item.type`]="{ item }">
                  {{ getUserType(item) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text> Voltar </v-btn>
      </v-card-actions>
    </v-card>
    <UpdateCompanyForm
      @store="select($event), $emit('store')"
      ref="UpdateCompanyForm"
    />
    <CompanySubscriptionForm
      :company="company"
      @store="select($event), $emit('store')"
      ref="CompanySubscriptionForm"
    />
    <CustomSubscriptionForm
      @store="select($event), $emit('store')"
      ref="CustomSubscriptionForm"
    />
    <WhatsappNotificationForm
      ref="WhatsappNotificationForm"
      @store="select($event), $emit('store')"
    />
    <EvoAdminForm ref="EvoAdminForm" @store="select($event), $emit('store')" />
  </v-dialog>
</template>

<script>
import CompanySubscriptionForm from "@/components/subscription/forms/CompanySubscriptionForm.vue";
import CustomSubscriptionForm from "@/components/subscription/forms/CustomSubscriptionForm.vue";
import UpdateCompanyForm from "@/components/admin/forms/UpdateCompanyForm.vue";
import WhatsappNotificationForm from "@/components/admin/forms/WhatsappNotificationForm.vue";
import EvoAdminForm from "@/components/admin/forms/EvoAdminForm.vue";

export default {
  components: {
    CompanySubscriptionForm,
    CustomSubscriptionForm,
    UpdateCompanyForm,
    WhatsappNotificationForm,
    EvoAdminForm,
  },
  data() {
    return {
      headers: [
        { text: "Plano", value: "plan.name" },
        {
          text: "Código Externo",
          value: "external_id",
          align: "center",
          width: "10%",
        },
        {
          text: "Data de Inicio",
          value: "start_at",
          align: "center",
          width: "1%",
        },
        { text: "Data de Fim", value: "ends_at", align: "center", width: "1%" },
        { text: "Origem", value: "vendor", align: "center", width: "1%" },
        { text: "Status", value: "status", align: "center", width: "1%" },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "1%",
        },
      ],

      userHeaders: [
        { text: "Nome", value: "name" },
        { text: "Email", value: "email" },
        { text: "Tipo", value: "type" },
        { text: "Status", value: "status" },
      ],
      editSubscription: false,
      dialog: false,
      company: {
        billing_whats_app_instance: {},
        session_whats_app_instance: {},
        subscriptions: [],
        status: "active",
        owner: {
          user: {
            main_address: {},
          },
        },
      },
    };
  },
  computed: {
    billingWhatsAppInstance() {
      return this.company?.billing_whats_app_instance;
    },
    sessionWhatsAppInstance() {
      return this.company?.session_whats_app_instance;
    },
  },

  methods: {
    open(company_id) {
      this.select(company_id);

      this.dialog = true;
    },
    select(company_id) {
      this.$loading.start();
      this.$http
        .show("admin/companies", company_id)
        .then((response) => {
          this.$loading.finish();

          this.company = response.company;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    resendSchedule() {
      this.$loading.start();
      this.$http
        .show("admin/resend-schedule", this.company.id)
        .then((response) => this.$loading.finish())
        .catch((error) => this.$loading.finish());
    },

    openCompanySubscriptionForm(subscription_id) {
      this.$refs.CompanySubscriptionForm.open(subscription_id);
    },
    openCustomSubscriptionForm() {
      this.$refs.CustomSubscriptionForm.open(this.company.id);
    },
    openUpdateCompanyForm() {
      this.$refs.UpdateCompanyForm.open(this.company.id);
    },
    subscriptionStatus(subscription) {
      let statuses = {
        sendding: "Aguardando assinatura",
        pending_payment: "Aguardando pagamento",
        waiting_payment: "Aguardando pagamento",
        processing: "Processando pagamento",
        paid: "Pago",
        unpaid: "Pagamento pendente",
        ended: "Pagamento pendente",
        canceled: "Cancelada",
      };

      return statuses[subscription.status];
    },
    getUserType(user) {
      if (user.company_user.is_attendant) {
        return "Atendente";
      }
      if (user.company_user.is_professional) {
        return "Profissional";
      }
      if (user.company_user.is_secretary) {
        return "Secretária";
      }
    },
    cancelSubscription(subscription_id) {
      this.$loading.start();
      this.$http
        .destroy("subscription/admin/manual-subscription", subscription_id)
        .then((response) => {
          this.select(this.company.id);
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    openWhatsappNotificationForm() {
      this.$refs.WhatsappNotificationForm.open(this.company.id);
    },

    storeObservations() {
      this.$loading.start();
      this.$http
        .update("admin/companies", this.company.id, {
          observations: this.company.observations,
        })
        .then((response) => {
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    resendRemindersSession() {
      this.$loading.start();
      this.$http
        .show("admin/resend-reminders-session", this.company.id)
        .then((response) => this.$loading.finish())
        .catch((error) => this.$loading.finish());
    },

    handleEvo() {
      this.$refs.EvoAdminForm.open(this.company);
    },
  },
};
</script>

<style>
</style>