<template>
  <v-dialog v-model="dialog" width="800px" persistent>
    <v-card>
      <v-card-title primary-title> Configurações de Notificação </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <app-select
              :items="whatsapp_instances"
              v-model="form.session_whats_app_instance"
              :item-text="(item) => getName(item)"
              return-object
              label="Instância de WhatsApp para Sessão"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-select
              :items="whatsapp_instances"
              v-model="form.billing_whats_app_instance"
              :item-text="(item) => getName(item)"
              return-object
              label="Instância de WhatsApp para Cobrança"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text>Cancelar</v-btn>
        <v-btn @click="handleSave()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      whatsapp_instances: [],
      dialog: false,
      form: {},
      company: {},
      drivers: {
        "\\App\\Services\\ZApi": "Z-API",
        "\\App\\Services\\EvolutionApi": "Evo",
        zapi: "Z-API",
      },

      status: {
        offline: "🔴",
        online: "🟢",
      },
      types: {
        session: "Sessão",
        billing: "Cobrança",
        company: "Empresa",
      },
    };
  },

  created() {
    this.reset();
  },
  methods: {
    async open(company_id) {
      this.reset();
      this.$loading.start();
      await this.$http
        .show("admin/company/whatsapp-notification", company_id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.company));
          this.getWhatsappApiInstances();
          this.$loading.finish();
          this.dialog = true;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.company));
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .update("admin/company/whatsapp-notification", this.form.id, this.form)
        .then((response) => {
          this.$loading.finish();
          this.dialog = false;
          this.$emit("store", this.form.id);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async getWhatsappApiInstances() {
      this.$loading.start();

      await this.$http
        .index("admin/whatsapp-instances", {
          type: "system",
        })
        .then((response) => {
          this.whatsapp_instances = response.whatsapp_instances;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    getName(item) {
      return `${item.name} | ${this.drivers[item.driver]} | ${
        this.types[item.type]
      } | ${this.status[item.info.status]}`;
    },
  },
};
</script>

<style>
</style>