<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <br />
        <v-row class="d-flex align-center">
          <v-col>
            <app-select
              :items="plans"
              v-model="form.plan"
              :item-text="
                (item) => `${item.name} - ${$format.moneyBr(item.value)}`
              "
              return-object
              label="Plano"
              hide-details
              dense
              @change="setSubscriptionValue()"
            />
          </v-col>
          <v-col>
            <v-col>
              <app-number-field label="Valor" v-model="form.amount" />
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Informe os dados para assinatura...</h3>
            <BaseBillingInformationForm
              v-model="form.billing_information"
              class="mt-10"
              :company="form.company"
              :user="form.company.owner.user"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBillingInformationForm from "@/components/subscription/forms/BaseBillingInformationForm.vue";

export default {
  components: {
    BaseBillingInformationForm,
  },

  data() {
    return {
      plans: [],
      dialog: false,

      form: {},
      baseForm: {
        id: null,
        vendor: "efi",
        type: "manual",
        amount: null,
        plan: {},
        company: {
          owner: {
            user: {},
          },
        },
        billing_information: {
          main_address: {},
        },
      },
    };
  },

  computed: {
    title() {
      return this.form.id
        ? "Editar Assinatura Personalizada"
        : "Nova Assinatura Personalizada";
    },
  },

  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async open(company_id) {
      await this.$http
        .show("admin/companies", company_id)
        .then((response) => {
          this.reset();
          this.form.company = response.company;
          this.form.billing_information = this.form.company.billing_information;
          this.getPlans();
          this.$loading.finish();
          this.dialog = true;
        })
        .catch((error) => this.$loading.finish());
    },

    getPlans() {
      this.$loading.start();
      this.$http
        .index("subscription/plans")
        .then((response) => {
          this.plans = response.plans;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    setSubscriptionValue() {
      this.form.amount = this.form.plan.value;
    },

    handleSave() {
      this.$loading.start();
      if (this.form.id) {
        this.update();
      } else {
        this.store();
      }
    },

    store() {
      this.$http
        .store("subscription/admin/manual-subscription", this.form)
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    update() {
      this.$http
        .update(
          "subscription/admin/manual-subscription",
          this.form.id,
          this.form
        )
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSave() {
      this.$emit("store", this.form.company.id);
      // this.dialog = false;
      this.$loading.finish();
    },
  },
};
</script>

<style>
</style>